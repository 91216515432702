import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ROICalculator from './ROICalculator';
import ROICalculatorWithExtraInfo from './ROICalculatorWithExtraInfo';

let history = createBrowserHistory();

let AppRoutes = [
	{ path: '/home', name: 'ROI Calculator', component: ROICalculator },
	{ path: '/roicalculator', name: 'ROI Calculator With Extra Info', component: ROICalculatorWithExtraInfo },
	{ redirect: true, to: '/home' }
];

const Routing = AppRoutes.map(
	(route, i) =>
		route.redirect ? (
			<Redirect key={i} to={route.to} />
		) : (
			<Route key={i} path={route.path} component={(props) => <route.component {...props} />} />
		)
);

const App = () => {
	return (
		<Router history={history}>
			<React.Fragment>
				<Switch>{Routing}</Switch>
			</React.Fragment>
		</Router>
	);
};

export default App;
