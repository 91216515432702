import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";

// Without Logo, Header, Footer
const ROICalculator = (props) => {
	const [ roiFormState, setRoiFormState ] = useState({
		numPatients: '',
		numHours: '',
		numLocations: '',
		numDaysOpen: ''
	});

	const [ result, setResult] = useState({
		costSavingPerMonth: '',
		costSavingPerYear: '',
		roiWithin: "2 Months",
	});

	const handleChange = e => {
		const { target: { name = '', value = '' } = {} } = e;
		if (isNaN(value)) {
			return false;
		}
		setRoiFormState({
			...roiFormState,
			[name]: value
		});
	};

	const formatNumber = value => {
		if(value === '') {
			return '';
		}
		return new Intl.NumberFormat('en-US', { 
			style: 'currency', 
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

    useEffect((props) => {
        console.log('props ', props);
        // if (props?.location?.pathname == "/roicalculator") {
            // props.history.push("/roicalculator");
        // }
    }, []);
	const { numPatients, numHours, numLocations, numDaysOpen } = roiFormState;
	const { costSavingPerMonth, costSavingPerYear } = result;
	return (
		<div>
			<div className="roiHeaderWrapper">
			</div>
			<Grid container justify="center">
				<Grid item xs={12} md={8}>
					<div className="roiFormWrapper">
						<h2 className="roiFormHeader">Business Details</h2>
						<form className="roiForm">
							<div className="roiFormFieldWrapper">
								<label for="numLocations">How many locations do you operate?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numLocations"
									value={numLocations}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label for="numHours">How many hours per day is each location open?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numHours"
									value={numHours}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label id="numDaysOpen">How many days per week is each location open?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numDaysOpen"
									value={numDaysOpen}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label for="numPatients">How many patients do you see each day per location?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numPatients"
									value={numPatients}
									onChange={handleChange}
								/>
							</div>
							<div className="roiSubmitBtnWrapper">
								<button type="button" className="roiSubmitBtn" 
									onClick={() => {
										if (numPatients && numHours && numLocations && numDaysOpen) {
											var patientvolweek = 0;
											if(numDaysOpen === 5){
												patientvolweek = numPatients*22;
											} else {
												patientvolweek = numPatients*30;
											}
											setResult({
												costSavingPerMonth: (12/(numPatients/numHours)*patientvolweek)*numLocations,
												costSavingPerYear: ((12/(numPatients/numHours)*patientvolweek)*numLocations)*12,
												roiWithin: "2 Months"
											});
										}
										else {
											alert('Zero is not allowed as an input. Please enter a different number.');
										}
										
									}}>
									Calculate
								</button>
							</div>
						</form>
					</div>
				</Grid>
			</Grid>
			<Grid container justify="center">
				<Grid item xs={12} md={8} className="ml-50">
					<div className="roiResultWrapper">
						<h2 className="roiResultHeader">Result</h2>
						<div className="roiResult">
							<div className="roiResultLabel">
								Cost Saving Per Month
							</div>
							<div className="roiResultVal">
								{formatNumber(costSavingPerMonth)}
							</div>
						</div>
						<div className="roiResult">
							<div className="roiResultLabel">
								Cost Saving Per Year
							</div>
							<div className="roiResultVal">
								{formatNumber(costSavingPerYear)}
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

export default ROICalculator;