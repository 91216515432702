import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";

//With Logo, Header, Footer
const ROICalculatorWithExtraInfo = () => {
    const [ roiFormState, setRoiFormState ] = useState({
		numPatients: '',
		numHours: '',
		numLocations: '',
		numDaysOpen: ''
	});

	const [ result, setResult] = useState({
		costSavingPerMonth: '',
		costSavingPerYear: '',
		roiWithin: "2 Months",
	});

	const handleChange = e => {
		const { target: { name = '', value = '' } = {} } = e;
		if (isNaN(value)) {
			return false;
		}
		setRoiFormState({
			...roiFormState,
			[name]: value
		});
	};

	const formatNumber = value => {
		if(value === '') {
			return '';
		}
		return new Intl.NumberFormat('en-US', { 
			style: 'currency', 
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	const { numPatients, numHours, numLocations, numDaysOpen } = roiFormState;
	const { costSavingPerMonth, costSavingPerYear } = result;
	return (
		<div className="appWithLogo">
			<img src="/img/pxLogo.png" className="roiLogo" alt='logo' />
			<div className="roiHeaderWrapper">
				<h1 className="roiHeader">ROI Calculator</h1>
				<div className="roiHeaderDetails">
					Please use the table below to calculate the estimate savings and ROI that your organization can gain by implementing +PX
				</div>
			</div>
			<Grid container justify="center">
				<Grid item xs={12} md={8}>
					<div className="roiFormWrapper">
						<h2 className="roiFormHeader">Business Details</h2>
						<form className="roiForm">
							<div className="roiFormFieldWrapper">
								<label for="numLocations">How many locations do you operate?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numLocations"
									value={numLocations}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label for="numHours">How many hours per day is each location open?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numHours"
									value={numHours}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label id="numDaysOpen">How many days per week is each location open?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numDaysOpen"
									value={numDaysOpen}
									onChange={handleChange}
								/>
							</div>
							<div className="roiFormFieldWrapper">
								<label for="numPatients">How many patients do you see each day per location?</label>
								<input
									type="text" 
									className="roiFormField"
									name="numPatients"
									value={numPatients}
									onChange={handleChange}
								/>
							</div>
							{/* <div className="roiFormDisclaimer">
								Disclaimer: Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div> */}
							<div className="roiSubmitBtnWrapper">
								<button type="button" className="roiSubmitBtn" 
									onClick={() => {
										if (numPatients && numHours && numLocations && numDaysOpen) {
											var patientvolweek = 0;
											if(numDaysOpen === 5){
												patientvolweek = numPatients*22;
											} else {
												patientvolweek = numPatients*30;
											}
											setResult({
												costSavingPerMonth: (12/(numPatients/numHours)*patientvolweek)*numLocations,
												costSavingPerYear: ((12/(numPatients/numHours)*patientvolweek)*numLocations)*12,
												roiWithin: "2 Months"
											});
										}
										else {
											alert('Zero is not allowed as an input. Please enter a different number.');
										}
										
									}}>
									Calculate
								</button>
							</div>
						</form>
					</div>
				</Grid>
			</Grid>
			<Grid container justify="center">
				<Grid item xs={12} md={8} className="ml-50">
					<div className="roiResultWrapper">
						<h2 className="roiResultHeader">Result</h2>
						<div className="roiResult">
							<div className="roiResultLabel">
								Cost Saving Per Month
							</div>
							<div className="roiResultVal">
								{formatNumber(costSavingPerMonth)}
							</div>
						</div>
						<div className="roiResult">
							<div className="roiResultLabel">
								Cost Saving Per Year
							</div>
							<div className="roiResultVal">
								{formatNumber(costSavingPerYear)}
							</div>
						</div>
						{/* <div className="roiResult">
							<div className="roiResultLabel">
								Return on investment within
							</div>
							<div className="roiResultVal">
								{roiWithin}
							</div>
						</div> */}
						<div className="roiSubmitBtnWrapper">
							<button type="button" className="roiSubmitBtn" onClick={() => {
							window.location.href = "https://www.pluspx.com/contact";
						}}>
								Start Saving
							</button>
						</div>
					</div>
				</Grid>
			</Grid>
			<div className="roiFooterWrapper">
				<div className="roiFooterFirst">PX is a QU-IN NOW LLC company © 2021. All rights reserved</div>
				<div className="roiFooterLast">
					<a href="mailto:info@pluspx.com" target="_blank" rel="noopener noreferrer">info@pluspx.com</a> &nbsp;|&nbsp;
					<div>1-866-532-0881</div> &nbsp;|&nbsp;
					<a href="https://www.linkedin.com/company/pluspx" target="_blank" rel="noopener noreferrer">LinkedIn</a>
				</div>
			</div>
		</div>
	);
}

export default ROICalculatorWithExtraInfo;